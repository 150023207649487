var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "in-app-page app-recommend" }, [
    _c("div", { staticClass: "content-box" }, [
      false
        ? _c("p", [
            _vm._v("邀请您的好友使用注册和体验 APP"),
            _c("br"),
            _vm._v("即可获得 ShowCoin 奖励"),
          ])
        : _vm._e(),
      _c("div", { staticClass: "coming" }, [
        _c("div", { staticClass: "img-wrap" }, [
          _c(
            "svg",
            {
              staticClass: "bi bi-hourglass-split",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "32",
                height: "32",
                fill: "currentColor",
                viewBox: "0 0 16 16",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z",
                },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "text" }),
        _vm._v(_vm._s(_vm.$t("referMiningComingSoon..."))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }