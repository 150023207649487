<template>
  <div class="in-app-page app-recommend">
    <div class="content-box">
      <p v-if="false">邀请您的好友使用注册和体验 APP<br />即可获得 ShowCoin 奖励</p>
      <div class="coming">
        <div class="img-wrap">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
            <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
          </svg>
        </div>
        <div class="text"></div>{{ $t("referMiningComingSoon...") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    const lang = this.$route.query.lang || 'zh'
    this.$i18n.locale = lang
  }
}
</script>
<style lang="scss">
.app-recommend {
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/app-recommend-bg.jpg") #FFFBF5 no-repeat top center;
  background-size: "cover";
  .content-box {
    font-size: 14px;
    max-width: 360px;
    margin: 0 auto;
    text-align: center;
    padding-top: 280px;
    line-height: 180%;
    .coming {
      color: #909399;
      margin-top: 80px;
      .img-wrap {
        color: #FFCA14;
      }
    }
  }
}
</style>
